import { React } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Global } from "@emotion/react"
import tw, { css, GlobalStyles } from "twin.macro"
import Header from "../components/navigation"
import Container from "../components/container"
import Breadcrumbs from "../components/breadcrumbs"
import ScrollTop from "../components/scroll-top-button"

const Layout = props => {
  const { isHomePage, children } = props
  const breadcrumbs = props.data?.breadcrumbs?.seo?.breadcrumbs || []
  const {
    mainMenu: { nodes: mainMenuItems },
    footerMenu: { nodes: footernMenuItems },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      mainMenu: allWpMenuItem(
        filter: { locations: { eq: PRIMARY }, parentId: { eq: null } }
      ) {
        nodes {
          id
          label
          uri
          childItems {
            nodes {
              id
              label
              uri
            }
          }
        }
      }
      footerMenu: allWpMenuItem(filter: { locations: { eq: FOOTER } }) {
        nodes {
          id
          path
          label
          url
        }
      }
    }
  `)

  return (
    <div>
      <GlobalStyles />
      <Global
        styles={css`
          body {
            ${tw`antialiased font-regular`}
            color: #25303B;
          }
          html {
            scroll-padding-top: 96px;
            @media (prefers-reduced-motion: no-preference) {
              scroll-behavior: smooth;
            }
            @media (min-width: 1024px) {
              scroll-padding-top: 172px;
            }
            @media (min-width: 1240px) {
              scroll-padding-top: 124px;
            }
            /*
            @media (min-width: 1240px) {
              font-size: calc(12.25px + 0.3vw);
              -webkit-marquee-increment: 0vw;
            }
            @media (min-width: 1920px) {
              font-size: 18.007px;
            }
            */
          }
          p.small {
            font-size: 80%;
          }
        `}
      />
      <div
        tw="relative flex min-h-screen flex-col items-stretch"
        className="global-wrapper"
        data-is-root-path={isHomePage}
      >
        <Header
          mainMenuItems={mainMenuItems}
          footernMenuItems={footernMenuItems}
        />
        <main tw="mt-28 flex-grow pb-14 md:pb-18 lg:mt-48 lg:pb-20" role="main">
          {breadcrumbs && breadcrumbs.length > 1 && (
            <Container>
              <div tw="mb-2 mt-4 lg:mb-4">
                <Breadcrumbs items={breadcrumbs} />
              </div>
            </Container>
          )}
          {children}
          <ScrollTop />
        </main>

        <footer tw="flex-shrink-0" role="contentinfo">
          <div tw="bg-lilac-500 py-10 md:py-16">
            <Container>
              <div tw="flex flex-wrap items-end md:gap-5 md:text-lg lg:flex-nowrap">
                <div tw="w-full pb-4 md:pb-0 lg:w-3/12">
                  Bundesstiftung Gleichstellung
                  <br />
                  Karl-Liebknecht-Str. 34
                  <br />
                  10178 Berlin
                </div>
                <div tw="w-full">
                  <a href="tel:+4930994057000" tw="text-link-negative">
                    +49 30-9940570-00
                  </a>
                  <br />
                  <a
                    href="mailto:info@bundesstiftung-gleichstellung.de"
                    tw="text-link-negative"
                  >
                    info@bundesstiftung-gleichstellung.de
                  </a>
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div tw="flex justify-between py-4 md:py-6">
              <div tw="flex">
                {/* <Icon svg={logoBmfsfj} tw="w-64 mr-10" />
                  <Icon svg={logoBffuza} tw="w-64 mr-10" /> */}
              </div>
              <ul tw="flex flex-wrap justify-end space-x-4 font-bold text-lg md:space-x-5 md:text-xl">
                {footernMenuItems.map(({ label, url, id }) => (
                  <li tw="" className="global-nav-item" key={`nav-${id}`}>
                    <Link
                      to={url || `/`}
                      tw="px-0.5 py-3 navLink-animated underline-lillac"
                      activeClassName="active"
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Container>
          {/* © {new Date().getFullYear()} */}
        </footer>
      </div>
    </div>
  )
}

export default Layout
